import React from 'react'

import './code-syntax.scss'

type SupportedLangs = 'js' | 'ts' | 'jsx' | 'tsx' | 'html' | 'css'

const CodeSyntax = (props: { children: React.ReactNode, lang?: SupportedLangs }) => (
  <>
    <span className={ `code-syntax ${props.lang}` }>
      { props.children }
    </span>
  </>
)

export default CodeSyntax

/**
 * - **df**: [all] default
 * - **cm**: [all] comments
 * - **vr**: [js] variable
 * - **bk**: [js] brackets
 * - **fn**: [js] function
 * - **st**: [js] string
 * - **nu**: [js] number
 * - **tg**: [html] tag
 * - **cl**: [css] class
 * - **id**: [css] id
 */
type SupportedCodes = 'vr' | 'bk' | 'fn' | 'st' | 'nu' | 'df' | 'tg' | 'cl' | 'id'

export const Code = (props: { children: React.ReactNode, c: SupportedCodes }) => (
  <>
    <span className={ `code ${props.c || 'df'}` }>
      { props.c === 'vr' ? 
        props.children.toString().replace(/[\s]/gmi, '') : 
        props.children }
    </span>
  </>
)

export const bk = (props: { t: 'o' | 'c' }) => (
  <Code c="bk">
    { props.t === 'o' ? '{' : '}' }
  </Code>
)