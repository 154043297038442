import React from "react"
import { anim } from "../../../services/animations/animation.service"
import { Power2 } from "gsap"

import './logo.scss'
import * as variables from '../../../variables.scss'

const Logo = () => {
  const [ glowState, setGlowState ] = React.useState()
  const paths = [
    { id: "ears", length: "16.86" },
    { id: "glasses", length: "41.48" },
    { id: "eyebrows", length: "69.66" },
    { id: "hair", length: "68.56" },
    { id: "hair-paths", length: "180.05" },
    { id: "beard", length: "33.60" },
    { id: "moustache", length: "23.92" },
    { id: "goatee", length: "14.13" },
  ]
  console.log('variables', variables)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="calc(70px - 8px)"
      viewBox="0 0 21.187 26.458"
      id="animated-logo"
      className={ glowState }
      onMouseEnter={ () => setGlowState('glow') }
      onMouseLeave={ () => setGlowState('') }
    >
      <g
        fill="none"
        stroke="var(--color-secondary)"
        strokeDasharray="none"
        strokeMiterlimit="4"
      >
        <path
          id="ears"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="0.151"
          d="M79.633 157.192c-.38.077-.904.276-1.28-.668-.594-1.495-.952-2.336-1.093-3.08-.343-1.809.408-1.994.76-2.066.322-.065.652.334 1.059.525m17.393-.337c.332-.365.671-.782 1.198-.592 1.106.4.274 3.387-.416 5.227-.135.358-.63.739-.983.59"
          transform="translate(-77.097 -137.872)"
        ></path>
        <path
          id="glasses"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M94.625 151.368l-4.414.171a.876.876 0 00-.832.741h-3.044a.876.876 0 00-.83-.593h-4.419a.876.876 0 00-.878.879v1.558c0 .487.357 1.332 1.483 1.332H84.9c.964 0 1.484-.845 1.484-1.332v-1.558c0-.03-.002-.06-.005-.09h2.99l.06 1.532c.018.487.57 1.311 1.533 1.274l3.206-.125c1.126-.043 1.45-.902 1.43-1.388l-.06-1.558a.877.877 0 00-.912-.843z"
          transform="translate(-77.097 -137.872)"
        ></path>
        <path
          id="eyebrows"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M143.765 209.796c-1.198.087-2.284-.574-3.376-1.055-3.215-1.415-6.108-.703-9.421.154-1.2.311-1.286-.607-.802-.961 4.112-3.014 9.59-3.12 13.868-.977 2.275 1.14 2.417 2.643-.269 2.839zM161.481 208.937c1.198.088 2.284-.574 3.377-1.055 3.215-1.414 6.108-.703 9.42.155 1.201.31 1.287-.607.803-.962-4.112-3.014-9.59-3.12-13.869-.976-2.274 1.14-2.416 2.643.27 2.838z"
          transform="translate(-77.097 -137.872) matrix(.28574 0 0 .28574 44.112 91.166)"
        ></path>
        <path
          id="hair"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth=".7"
          d="M79.458 152.36c.134-.922.177-1.933.486-2.43.252-1.757.122-4.034 2.052-4.806 1.788-.715 3.85.06 5.775.087 1.958.03 4.228-.825 5.809-.315 2.031.656 1.994 2.989 2.425 4.945.201.58.26 1.331.303 2.105.384-.987.657-1.906.897-4.081.133-1.21-.027-2.903-.898-3.497.078-.835.053-.924-.371-1.31.026-1.568-.586-2.057-1.195-2.39-2.732-2.595-5.845-2.854-8.984-2.375-1.99-.148-4.284 1.062-4.61 1.861-2.918 1.35-2.68 3.326-2.175 4.511-1.42 1.344-.697 4.41.107 7.238.107.219.272.24.38.457z"
          transform="translate(-77.097 -137.872)"
        ></path>
        <path
          id="hair-paths"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="1.058"
          d="M130.927 189.638c-6.448-1.163-8.398 1.673-11.008 3.922M130.927 189.638c-2.333-1.754-5.086-2.981-8.307-2.426M150.343 188.805c-2.516-7.54-23.178-11.16-28.485-9.34M150.343 188.805c-1.2-10.829-11.573-18.586-20.228-17.28M150.343 188.805c.089-2.34.278-17.403-13.544-21.839M155.816 188.375c-.28-3.347 2.14-19.68-9.747-23.137M176.012 189.183c-.699-2.831 3.424-7.824 1.137-15.038M176.012 189.183c1.802-1.805 2.432-2.812 4.882-7.052M176.012 189.183c3.128-1.496 4.212-2.464 6.319-4.68M176.012 189.183c4.435-1.446 8.687 1.25 9.392 3.672"
          transform="translate(-77.097 -137.872) matrix(.28574 0 0 .28574 44.112 91.166)"
        ></path>
        <path
          id="beard"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M79.29 152.219l.343 4.973c.134 1.941 3.08 6.912 8.595 6.912 4.283 0 8.043-4.123 8.043-7.312l.037-4.846"
          transform="translate(-77.097 -137.872)"
        ></path>
        <path
          id="moustache"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth="4"
          d="M166.545 236.897c-4.33-.322-6.798-3.932-11.278-3.498M139.757 236.897c4.33-.322 6.797-3.932 11.277-3.498"
          transform="translate(-77.097 -137.872) matrix(.28574 0 0 .28574 44.112 91.166)"
        ></path>
        <path
          id="goatee"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".7"
          d="M92.04 162.998c-.146-.258-.387-.67-.49-1.063-.628-.195-.971 1.425-1.615 1.292-1.558-.322-.446-1.877-1.143-2.304a1.402 1.402 0 00-.665-.217c-.285-.016-.573.058-.886.19-.56.235.09 2.327-1.177 2.217-.7-.062-1.23-1.542-1.91-1.366a4 4 0 01-.36.956"
          transform="translate(-77.097 -137.872)"
        ></path>
      </g>
    </svg>
  )
}

export default Logo
