/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "../header"
import CodeSyntax, { Code } from "../code-syntax/code-syntax"
import { StorageContext } from "../../contexts/storage/storage.context"

import "global.scss"
import "./layout.scss"
import Navbar from '../navbar'

function Layout({ children }) {
  const aws = useContext(StorageContext)
  const [navStatus, setNavStatus] = useState()
  const [navCount, setNavCount] = useState()

  useEffect(() => {
    ;(async () => {
      await aws.set("navigating", false)
      const navigating = await aws.find("navigating")
      const navCount = await aws.find("navCount")
      setTimeout(async () => {
        setNavStatus(navigating || false)
        console.log(await aws.find("navigating"))
      }, 2000)
      setNavCount(navCount)
    })()
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar />
      <Header siteTitle={
        `${data.site.siteMetadata.title}`
      } />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "right",
          width: `calc(100vw - var(--nav-size) - 60px)`,
          height: `calc(100vh - 68px - 86px)`,
          paddingTop: 0,
        }}
      >
        {children}
        <footer>
          <pre>
            <CodeSyntax lang="jsx">
              <Code c="bk">{`)`}</Code>;
              <br/>
              <Code c="cm">{`// `}</Code>
              <Code c="vr b">{`\@see `}</Code>
              <Code c="cm">{`confira alguns dos meus `}</Code>
              <Code c="bk">
                <u>
                  <Link to="/projects">Projetos</Link>
                </u>
              </Code>
            </CodeSyntax>
          </pre>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
