import PropTypes from "prop-types"
import React from "react"
import CodeSyntax, { Code } from "../code-syntax/code-syntax"

class Header extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <header>
        <pre>
          <CodeSyntax lang="jsx">
            <Code c="fn">{`export const `}</Code>
            <Code c="vr">{`Portfolio`}</Code>
            {` = `}
            <Code c="bk">(
              <Code c="fn">{`{`}</Code>
              <Code c="df">{` skills, projects `}</Code>
              <Code c="fn">{`}`}</Code>
            )</Code>
            <Code c="fn">{` => `}</Code>
            <Code c="bk">{`(`}</Code>
          </CodeSyntax>
        </pre>
      </header>
    )
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
